<template>
  <div v-if="shownArticleList.length > 0">
    <template
      v-for="(item, index) in shownArticleList"
      :key="'article' + index"
    >
      <ArticleTeaser :article="item" :index="index" :small="true" />
      <CommonDivider />
    </template>
  </div>
</template>

<script setup lang="ts">
import type { ContentArticle } from '~/typesManual/content_api/article'

const userStore = useUserStore()

const shownArticleList = computed(() => {
  if (!userStore.user?.readingList) return []
  return userStore.user.readingList
    .filter((item) => {
      return !item.articleRead
    })
    .slice(0, 3)
    .map((item) => {
      return item.article
    }) as ContentArticle[] // TODO REMOVE HACK WHEN TEASER IS REFACTORED
})
</script>
